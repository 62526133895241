import React from 'react';
import { Route, NotFound } from 'cccisd-laravel-boilerplate';
import defaultProps from 'recompose/defaultProps.js';
import { Switch } from 'cccisd-react-router';
import { AdminRoutes } from 'cccisd-laravel-appdefs';
import { AuthRedirect } from 'cccisd-laravel-nexus';
import { Quest } from 'cccisd-laravel-assignment';
import { ResourceCenter } from 'cccisd-laravel-resources';

// Layouts
import MainLayout from './layouts/MainLayout';
import QuestLayout from './layouts/QuestLayout';

// Pages
/* eslint-disable import/no-extraneous-dependencies, import/no-unresolved */
import Welcome from './pages/Welcome';
import OrgAdminRegister from './pages/OrgAdminRegister';
import PreventionTeamMemberRegister from './pages/PreventionTeamMemberRegister';
import AdminProgress from './pages/AdminProgress';
import Manage from './pages/Manage';
import Dashboard from './pages/Dashboard';
import Data from './pages/Data';

// Laravel packages
import Nexus from 'bundle-loader?lazy!cccisd-laravel-nexus';

// Bind MainLayout by default
const AppRoute = defaultProps({ layout: MainLayout })(Route);

const MainLayoutFluid = defaultProps({ className: 'container-fluid' })(MainLayout);

let Fortress = window.cccisd.fortress;
let AppDefinitions = window.cccisd.appDefs;

const componentMatch = {
    AdminProgress,
    Manage,
    Welcome,
    Dashboard,
    Data,
    ResourceCenter,
};

const layoutMatch = {
    MainLayout,
    MainLayoutFluid,
};

export default () => (
    <Switch>
        {AppDefinitions.routes.map(route => {
            const Component = componentMatch[route.componentHandle];
            if (!Component) {
                return null;
            }

            return (
                <AppRoute
                    key={route.handle}
                    path={route.url}
                    component={Component}
                    componentProps={route.componentProps}
                    exact
                    layout={
                        'layout' in route && layoutMatch[route.layout] ? layoutMatch[route.layout] : MainLayoutFluid
                    }
                />
            );
        })}
        <AppRoute path="/" component={Welcome} exact />
        <AppRoute path="/welcome" component={Welcome} />
        <AppRoute path="/login" component={Welcome} exact />
        <AppRoute path="/uJp3x4nM" component={OrgAdminRegister} exact />
        <AppRoute path="/register/:groupHash" component={PreventionTeamMemberRegister} />
        {/* {Fortress.auth() && <AppRoute path="/dashboard" component={Dashboard} />} */}

        <AppRoute path={['/quest/preview', '/d', '/survey']} component={Quest} layout={QuestLayout} />

        {/* Laravel packages routes */}
        {AdminRoutes(MainLayoutFluid)}

        <AppRoute
            path="/account"
            layout={null}
            component={Nexus}
            componentProps={{ defaultLayout: MainLayout, registrationSurveyLayout: QuestLayout }}
        />

        <AppRoute path={Fortress.settings.config.after_login_url} component={AuthRedirect} exact />

        {/* Not found page */}
        <AppRoute component={NotFound} />
    </Switch>
);
