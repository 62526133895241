import React, { useEffect, useState } from 'react';
import { client } from 'cccisd-apollo';
import { Field, CccisdInput } from 'cccisd-formik';
import Loader from 'cccisd-loader';
import { Redirect, useParams } from 'cccisd-react-router';
import { RegisterForm } from 'cccisd-laravel-nexus';
import groupQuery from './group.graphql';
import style from './style.css';

const Appdefs = window.cccisd.appDefs;
const Fortress = window.cccisd.fortress;

const PreventionTeamMemberRegister = () => {
    const organizationLabel = Appdefs.pawn.groups.find(g => g.handle === 'organization').label;
    const [group, setGroup] = useState(false);

    if (Fortress.auth()) {
        return (
            <div className="alert alert-warning">
                <p>You must be logged out to register a new user.</p>
            </div>
        );
    }

    const { groupHash } = useParams();

    if (!groupHash) {
        return <Redirect to="/" />;
    }

    useEffect(() => {
        async function getGroup() {
            const resp = await client.query({
                query: groupQuery,
                variables: { hash: groupHash },
                fetchPolicy: 'network-only',
            });

            let groupResult = resp?.data?.groups?.organization?.group || {};
            if (!groupResult?.groupId) {
                groupResult = { groupId: -1, label: '' };
            }

            setGroup(groupResult);
        }

        if (groupHash) {
            getGroup();
        }
    }, [groupHash]);

    if (group === false) {
        return <Loader loading />;
    }

    if (group?.groupId === -1 || !group.groupId) {
        return (
            <div className="alert alert-warning">
                <p>Incorrect registration link. Please consult your administrator for the correct link.</p>
                <p>
                    If you think this message is shown in error, please contact{' '}
                    <a href="mailto:support@3cisd.com">support@3cisd.com</a> for technical support.
                </p>
            </div>
        );
    }

    return (
        <div className={style.form}>
            <h2>Register</h2>
            <p className={style.groupLabel}>
                <i>
                    Registering for {organizationLabel} <b>{group.label}</b>...
                </i>
            </p>
            <RegisterForm
                // eslint-disable-next-line jsx-a11y/aria-role
                role="preventionTeamMember"
                group={group.groupId}
                showPasswordFields
                emailLabel="E-mail / Username:"
                renderAdditionalFields={() => <Field name="jobTitle" component={CccisdInput} label="Job Title" />}
            />
        </div>
    );
};

export default PreventionTeamMemberRegister;
