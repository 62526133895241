import React, { useEffect, useState } from 'react';
import { isFuture, parseISO } from 'date-fns';
import { client } from 'cccisd-apollo';
import Checkmark from 'cccisd-icons/checkmark-circle';
import { DeploymentPlayer } from 'cccisd-laravel-assignment';
import Loader from 'cccisd-loader';
import Modal from 'cccisd-modal';
import metricsPawnQuery from './metricsPawn.graphql';
import AddActivity from './AddActivity';
import DashTable from './DashTable';
import style from './style.css';

const TIMETRACKER_DEPLOYMENT_HANDLE_PREFIX = 'preventionist_time_'; // i.e. preventionist_time_1

const Fortress = window.cccisd.fortress;
const dates = window.cccisd.appDefs.app.dates;
const isTeamMemberOnly = Fortress.user.acting.role.handle === 'preventionTeamMember';
const hasEditPermission = !!(
    Fortress.user.acting.role.handle === 'orgAdmin' || Fortress.user.acting.role_data.isEditPermission
);

const Dashboard = () => {
    const settings = Fortress.user.acting.group.settings;
    const activities = settings.activities || [];
    const activeTimepoint = dates.find(date => {
        return isFuture(parseISO(date.closesAtISO)) && !isFuture(parseISO(date.opensAtISO));
    })?.timepoint;

    const [isLoading, setIsLoading] = useState(true);
    const [metricsPawn, setMetricsPawn] = useState(null);
    const [supportContact, setSupportContact] = useState('');
    const [isTimetrackerComplete, setIsTimetrackerComplete] = useState(false);

    useEffect(() => {
        getInitialData();
    }, []);

    const getInitialData = async () => {
        setIsLoading(true);
        const response = await client.query({
            query: metricsPawnQuery,
            fetchPolicy: 'network-only',
            variables: {
                groupId: Fortress.user.acting.group.id,
                activeTimepoint: activeTimepoint + '' || '-1',
            },
        });

        const supportName = isTeamMemberOnly
            ? response.data.roles.orgAdmin?.user?.username || 'support@3cisd.com'
            : 'support@3cisd.com';
        setSupportContact(supportName);

        const mPawn = response.data.roles.metricspawn.pawn;
        setMetricsPawn(mPawn);

        if (activeTimepoint) {
            setIsTimetrackerComplete(response.data.roles.metricspawn.assignmentProgressCount > 0);
        }

        setIsLoading(false);
    };

    const renderIppwTime = () => {
        if (!activeTimepoint) {
            return;
        }

        if (isTimetrackerComplete) {
            return (
                <>
                    <button type="button" className={'btn btn-primary ' + style.ippwButton} disabled>
                        IPPW Time <Checkmark spaceLeft />
                    </button>
                </>
            );
        }

        return (
            <Modal
                trigger={
                    <button type="button" className={'btn btn-primary ' + style.ippwButton}>
                        IPPW Time
                    </button>
                }
                modalClassName={style.ippwTimeModal}
                title="IPPW Time Survey"
                afterClose={getInitialData}
            >
                <DeploymentPlayer
                    pawnId={metricsPawn.pawnId}
                    pawnHash={metricsPawn.pawnHash}
                    deploymentHandle={TIMETRACKER_DEPLOYMENT_HANDLE_PREFIX + activeTimepoint}
                    disableLayout
                />
            </Modal>
        );
    };

    if (isLoading) {
        return <Loader loading />;
    }

    const noPermissionMessage = isTeamMemberOnly && !hasEditPermission && (
        <div className="alert alert-warning">
            <p>
                You may view, but not edit I-PAcT data. If you think you should be able to edit I-PAcT data, please
                contact <a href={'mailto:' + supportContact}>{supportContact}</a> to change your account permissions.
            </p>
        </div>
    );

    if (!activities?.length && !hasEditPermission) {
        return (
            <>
                {noPermissionMessage}
                <div className="alert alert-info">
                    <p>No I-PAcT data to view at this time. No responses have been submitted yet.</p>
                </div>
            </>
        );
    }

    if (!activities?.length) {
        return (
            <div className={'alert alert-info ' + style.noActivites}>
                <p>
                    Welcome! Click <b>Add Prevention Activiy</b> to get started.
                </p>
                <AddActivity settings={settings} />
            </div>
        );
    }

    return (
        <>
            {noPermissionMessage}

            {hasEditPermission && (
                <div style={{ display: 'flex' }}>
                    {renderIppwTime()}
                    <AddActivity settings={settings} />
                </div>
            )}

            {settings && (
                <DashTable
                    metricsPawn={metricsPawn}
                    settings={settings}
                    activeTimepoint={activeTimepoint}
                    hasEditPermission={hasEditPermission}
                />
            )}
        </>
    );
};

export default Dashboard;
