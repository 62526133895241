import React, { useState } from 'react';
import Table from 'cccisd-graphql-table';
import IconCaution from 'cccisd-icons/warning';
import query from './query.graphql';
import style from './style.css';
import SendEmail from './SendEmail';

const Appdefs = window.cccisd.appDefs;
const dates = Appdefs.app.dates;
const serviceField = Appdefs.pawn.fields.find(f => f.handle === 'service');

const AdminProgress = () => {
    const [timepoint, setTimepoint] = useState('1');

    const columns = [
        {
            name: 'group.label',
            label: 'Military Organization',
            sort: true,
            filter: true,
        },
        {
            name: 'fields.service',
            label: 'Service',
            sort: true,
            filter: true,
            filterSettings: {
                type: 'selectbox',
                options: serviceField.values.map(v => ({ value: v.value, label: v.name })),
            },
            render: ({ value }) => {
                return serviceField.values.find(v => v.value === value)?.name;
            },
        },
        {
            name: 'group.settings',
            label: 'Activities',
            sort: true,
            filter: true,
            render: ({ row, value }) => {
                const allActivities = row['group.settings.activities'];
                if (!allActivities?.length) {
                    return '0';
                }

                const activitieAtTheTime = allActivities.filter(act => {
                    return parseInt(act?.createdDuringTimepoint || 1, 10) <= parseInt(timepoint, 10);
                });
                return activitieAtTheTime.length;
            },
        },
        {
            name: 'childRoles.metricspawn.activitesComplete',
            label: 'Activities Completed',
            sort: true,
            filter: true,
        },
        {
            name: 'childRoles.metricspawn.lastVisitedAt.lastVisitedAt',
            label: 'Last Visited',
            sort: true,
            filter: true,
        },
        {
            name: 'childRoles.metricspawn.completedAt.completedAt',
            label: 'Completed',
            sort: true,
            filter: true,
            render: ({ loading, row }) => {
                if (loading) {
                    return '';
                }

                const allActivities = row['group.settings.activities'];
                if (!allActivities?.length) {
                    return '';
                }

                const expectedActivitiesComplete = allActivities.filter(act => {
                    return parseInt(act?.createdDuringTimepoint || 1, 10) <= parseInt(timepoint, 10);
                });

                const isActivitesComplete =
                    row['childRoles.metricspawn.activitesComplete'] >= expectedActivitiesComplete.length;
                const isTimetrackerComplete = row['childRoles.metricspawn.timetrackerComplete'] > 0;

                if (isActivitesComplete && isTimetrackerComplete) {
                    return row['childRoles.metricspawn.completedAt.completedAt'];
                }

                if (isActivitesComplete && !isTimetrackerComplete) {
                    return (
                        <span>
                            <span className="text-warning">
                                <IconCaution spaceRight />
                            </span>
                            Missing IPPW Time
                        </span>
                    );
                }

                return '';
            },
        },
        {
            name: 'group.groupId',
            label: 'Send Email',
            render: props => {
                return <SendEmail {...props} />;
            },
        },
    ];

    return (
        <>
            <h1>Progress</h1>

            <div className={'well ' + style.timepointSelector}>
                <form>
                    <div className="form-group">
                        <label htmlFor="timepoint">Timepoint:</label>
                        <select
                            className="form-control"
                            id="timepoint"
                            value={timepoint}
                            onChange={e => setTimepoint(e.target.value)}
                        >
                            {dates.map(date => {
                                return (
                                    <option key={date.timepoint} value={date.timepoint + ''}>{`Timepoint ${
                                        date.timepoint
                                    } (${date.opensAtISO.substr(0, 10)} to ${date.closesAtISO.substr(0, 10)})`}</option>
                                );
                            })}
                        </select>
                    </div>
                </form>
            </div>

            {timepoint ? (
                <Table rowKey="group.groupId" query={query} graphqlVariables={{ timepoint }} columns={columns} />
            ) : (
                <div className="alert alert-info">Please select a timepoint above.</div>
            )}
        </>
    );
};

export default AdminProgress;
