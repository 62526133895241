import React from 'react';
import copy from 'copy-to-clipboard';
import IconCopy from 'cccisd-icons/copy3';
import { Manage as AppdefManage } from 'cccisd-laravel-appdefs';
import notification from 'cccisd-notification';
import { useLocation } from 'cccisd-react-router';
import Tooltip from 'cccisd-tooltip';
import style from './style.css';

const Appdefs = window.cccisd.appDefs;
const Boilerplate = window.cccisd.boilerplate;
const Fortress = window.cccisd.fortress;

const Manage = props => {
    const { hash: selectedTab } = useLocation();
    const hash = Fortress.user.acting?.group?.random_hash;
    const link = Boilerplate.url('/register/' + hash);
    const roleLabelPlural = Appdefs.pawn.roles.find(r => r.handle === 'preventionTeamMember').labelPlural;

    function copyLink() {
        copy(link);
        notification({ message: 'Copied!', type: 'success', duration: 3000 });
    }

    function renderGroupRegistrationLink() {
        if (!hash || !selectedTab.includes('preventionTeamMember')) {
            return;
        }

        return (
            <div className="alert alert-info">
                <p className={style.bigFont}>
                    Add <b>{roleLabelPlural}</b> using the form below. Alternatively, they can register themselves at{' '}
                    <Tooltip title="Copy to clipboard">
                        <a
                            href="#"
                            onClick={e => {
                                e.preventDefault();
                                copyLink();
                            }}
                        >
                            {link}
                        </a>
                    </Tooltip>
                    &nbsp;&nbsp;
                    <Tooltip title="Copy to clipboard">
                        <button className="btn btn-info btn-xs" onClick={copyLink} type="button">
                            <IconCopy />
                        </button>
                    </Tooltip>
                </p>
                <br />
                <p className={style.littleFont}>
                    Note: <b>{roleLabelPlural}</b> who self-register will not be able to edit I-PAcT data by default.
                    You can toggle their Edit Permission here in the Manage tab after they register.
                </p>
            </div>
        );
    }

    return (
        <div>
            <h1>Manage</h1>
            {renderGroupRegistrationLink()}
            <AppdefManage {...props} />
        </div>
    );
};

export default Manage;
