import React from 'react';
import { isFuture, parseISO } from 'date-fns';
import axios from 'cccisd-axios';
import { Formik, Form, Field, CccisdInput } from 'cccisd-formik';
import Modal from 'cccisd-modal';
import notification from 'cccisd-notification';

const Appdefs = window.cccisd.appDefs;
const Boilerplate = window.cccisd.boilerplate;
const Fortress = window.cccisd.fortress;

const ACTIVITY_LIMIT = 20;

const AddActivity = ({ settings }) => {
    const onSubmit = async values => {
        const currentTimepoint = Appdefs.app.dates.find(d => {
            return isFuture(parseISO(d.closesAtISO));
        });
        if (!currentTimepoint?.timepoint) {
            notification({ message: 'Failed to save Activity. Could not calculate current timepoint', type: 'danger' });
            return;
        }

        const newActivities = Array.isArray(settings?.activities) ? [...settings.activities] : [];
        if (newActivities.length >= ACTIVITY_LIMIT) {
            notification({ message: 'Cannot add more than 20 activities', type: 'danger' });
            return;
        }

        const newActivityIdMap = {};
        newActivities.forEach(act => {
            newActivityIdMap[act.id] = act;
        });

        let nextId = 1;
        while (nextId < ACTIVITY_LIMIT) {
            if (newActivityIdMap[nextId]) {
                nextId++;
            } else {
                break;
            }
        }

        newActivities.push({
            id: nextId,
            name: values.name,
            createdDuringTimepoint: currentTimepoint.timepoint,
            deletedDuringTimepoint: null,
        });

        const response = await axios.put(
            Boilerplate.route('api.nexus.group.update', { group: Fortress.user.acting.group.id }),
            {
                settings: {
                    ...settings,
                    activities: newActivities,
                },
            }
        );

        if (response?.status === 200 || response?.data?.status === 'success') {
            window.location.reload();
        } else {
            notification({ message: 'Failed to save Activity', type: 'danger', data: response.data });
        }
    };

    const validation = values => {
        const errors = {};

        if (!values.name) {
            errors.name = 'You must enter a name.';
        }

        return errors;
    };

    return (
        <Modal
            trigger={
                <button type="button" className="btn btn-default">
                    Add Prevention Activity
                </button>
            }
            size="large"
            title="Add Prevention Activity"
        >
            <div>
                <Formik
                    onSubmit={onSubmit}
                    validate={validation}
                    render={() => (
                        <Form>
                            <Field name="name" component={CccisdInput} label="Activity Name" />

                            <button className="btn btn-primary" type="submit">
                                Submit
                            </button>
                        </Form>
                    )}
                />
            </div>
        </Modal>
    );
};

export default AddActivity;
