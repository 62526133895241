import React, { useState } from 'react';
import {
    Field,
    Form,
    Formik,
    CccisdCheckboxInput,
    CccisdInput,
    CccisdPasswordInput,
    CccisdSelect,
} from 'cccisd-formik';
import IconRight from 'cccisd-icons/arrow-right8';
import IconLeft from 'cccisd-icons/arrow-left8';
import Loader from 'cccisd-loader';
import validator from 'cccisd-validator';
import style from './style.css';

const Appdefs = window.cccisd.appDefs;

const RegisterForm = ({ installationList, onSubmit, pageNum, setPageNum }) => {
    const [submittedPageList, setSubmittedPageList] = useState([]);
    const organizationLabel = Appdefs.pawn.groups.find(g => g.handle === 'organization').label;
    const organizationLabelLong = Appdefs.pawn.groups.find(g => g.handle === 'organization').labelLong;
    const countryField = Appdefs.pawn.fields.find(f => f.handle === 'country');
    const stateField = Appdefs.pawn.fields.find(f => f.handle === 'state');
    const serviceField = Appdefs.pawn.fields.find(f => f.handle === 'service');
    const usaCountryValue = '44';

    // supresses controlled vs uncontrolled warning
    const initialValues = {
        termsandconds: false,
        reserve: 'na',
        nationalGuard: 'na',
    };

    function validatePageOne(vals) {
        // console.log(validator(vals.email, { types: ['email'] }));

        let errors = {};
        if (!vals.first_name) {
            errors.first_name = 'First name is required';
        }
        if (!vals.last_name) {
            errors.last_name = 'Last name is required';
        }
        if (!vals.email) {
            errors.email = 'E-mail is required';
        } else if (validator(vals.email || '', { types: ['email'] }).status !== 'pass') {
            errors.email = 'Must be valid e-mail address';
        }

        if (vals.password !== vals.repeatPassword) {
            errors.password = 'Passwords must match.';
            errors.repeatPassword = 'Passwords must match.';
        }
        if (!vals.password) {
            errors.password = 'Password is required.';
        } else if (vals.password.length < 8) {
            errors.password = 'Password must be at least 8 characters in length';
        }

        if (!vals.repeatPassword) {
            errors.repeatPassword = 'Must confirm password.';
        } else if (vals.repeatPassword.length < 8) {
            errors.repeatPassword = 'Password must be at least 8 characters in length';
        }

        return errors;
    }

    function validatePageTwo(vals) {
        let errors = {};
        if (!vals.group) {
            let msg = organizationLabel + ' name is required.';
            if (!vals.country) {
                msg += ' Must select a country in order to select ' + organizationLabel;
            }

            errors.group = msg;
        }

        if (!vals.country) {
            errors.country = organizationLabel + ' country is required';
        }

        return errors;
    }

    function validatePageThree(vals) {
        let errors = {};

        if (!vals.termsandconds) {
            errors.termsandconds = 'The privacy policy and terms of use must be accepted to continue';
        }

        return errors;
    }

    return (
        <Formik
            onSubmit={onSubmit}
            validateOnBlur={submittedPageList.includes(pageNum)}
            validateOnChange={submittedPageList.includes(pageNum)}
            initialValues={initialValues}
            validate={vals => {
                return { ...validatePageOne(vals), ...validatePageTwo(vals), ...validatePageThree(vals) };
            }}
        >
            {formikBag => (
                <Form className={style.form}>
                    <h2 className={style.heading}>Registration</h2>
                    <div className={style.steps}>
                        <div className={style.step}>
                            <div className={`${style.stepNum} ${pageNum === 1 ? style.active : style.isDone}`}>1</div>
                            <div className={`${style.stepLabel} ${pageNum === 1 ? style.active : ''}`}>
                                User Profile
                            </div>
                        </div>
                        <div className={style.beam}>
                            <div className={`${style.bar} ${pageNum === 1 ? style.isActive : style.isDone}`} />
                        </div>
                        <div className={style.step}>
                            <div
                                className={`${style.stepNum} ${(() => {
                                    if (pageNum === 2) {
                                        return style.active;
                                    }
                                    if (pageNum === 3) {
                                        return style.isDone;
                                    }
                                })()}`}
                            >
                                2
                            </div>
                            <div className={`${style.stepLabel} ${pageNum === 2 ? style.active : ''}`}>Location</div>
                        </div>
                        <div className={style.beam}>
                            <div
                                className={`${style.bar} ${(() => {
                                    if (pageNum === 1) {
                                        return style.isNotDone;
                                    }
                                    if (pageNum === 2) {
                                        return style.isActive;
                                    }
                                    return style.isDone;
                                })()}`}
                            />
                        </div>
                        <div className={style.step}>
                            <div
                                className={`${style.stepNum} ${(() => {
                                    if (formikBag.isSubmitting) {
                                        return style.isDone;
                                    }
                                    if (pageNum === 3) {
                                        return style.active;
                                    }
                                })()}`}
                            >
                                3
                            </div>
                            <div
                                className={`${style.stepLabel} ${
                                    pageNum === 3 && !formikBag.isSubmitting ? style.active : ''
                                }`}
                            >
                                Submit
                            </div>
                        </div>
                    </div>

                    <div style={{ display: pageNum === 1 && !formikBag.isSubmitting ? 'block' : 'none' }}>
                        <Field name="first_name" component={CccisdInput} label="First Name:" showErrorImmediately />
                        <Field name="last_name" component={CccisdInput} label="Last Name:" showErrorImmediately />
                        <Field
                            name="email"
                            autoComplete="off" // stop the browser from autocompleting both the Register form and Login form
                            component={CccisdInput}
                            label="E-mail:"
                            type="email"
                            showErrorImmediately
                        />
                        <Field name="jobTitle" component={CccisdInput} label="Job Title:" showErrorImmediately />
                        <Field
                            name="password"
                            autoComplete="new-password" // stop the browser from autocompleting both the Register form and Login form
                            component={CccisdPasswordInput}
                            showPasswordToggle
                            label="Password:"
                            showErrorImmediately
                        />
                        <Field
                            name="repeatPassword"
                            autoComplete="new-password" // stop the browser from autocompleting both the Register form and Login form
                            component={CccisdPasswordInput}
                            showPasswordToggle
                            label="Confirm Password:"
                            showErrorImmediately
                        />
                        <div className={style.buttonContainer}>
                            <span />
                            <button
                                className="btn btn-link"
                                onClick={() => {
                                    if (!submittedPageList.includes(1)) {
                                        setSubmittedPageList(prev => [...prev, 1]);
                                    }

                                    let errs = validatePageOne(formikBag.values);
                                    errs = {
                                        ...errs,
                                        // combine with possible errors returned from backend
                                        email: errs.email || formikBag.errors?.email,
                                        password: errs.password || formikBag.errors?.password,
                                        repeatPassword: errs.repeatPassword || formikBag.errors?.repeatPassword,
                                    };

                                    if (Object.values(errs).some(err => err)) {
                                        formikBag.setErrors(errs);
                                    } else {
                                        formikBag.setErrors({});
                                        setPageNum(2);
                                    }
                                }}
                                type="button"
                            >
                                Next
                                <IconRight spaceLeft />
                            </button>
                        </div>
                    </div>

                    <div style={{ display: pageNum === 2 && !formikBag.isSubmitting ? 'block' : 'none' }}>
                        <Field
                            name="country"
                            component={CccisdSelect}
                            label={organizationLabelLong + ' Location:'}
                            options={[
                                { value: '', label: '-- Choose country --' },
                                ...countryField.values.map(v => ({ value: v.value, label: v.name })),
                            ]}
                            onChange={event => {
                                const countryCode = event.currentTarget.value;
                                formikBag.setFieldValue('country', countryCode);
                                if (countryCode !== usaCountryValue) {
                                    formikBag.setFieldValue('state', '');
                                }

                                formikBag.setFieldValue('group', '');
                            }}
                            showErrorImmediately
                        />
                        {formikBag.values.country === usaCountryValue && (
                            <Field
                                name="state"
                                component={CccisdSelect}
                                label={organizationLabelLong + ' State (optional):'}
                                options={[
                                    { value: '', label: '-- Choose state --' },
                                    ...stateField.values.map(v => ({ value: v.value, label: v.name })),
                                ]}
                                onChange={event => {
                                    const stateCode = event.currentTarget.value;
                                    formikBag.setFieldValue('state', stateCode);
                                    formikBag.setFieldValue('group', '');
                                }}
                                showErrorImmediately
                            />
                        )}
                        {(() => {
                            const options = [];
                            installationList.forEach(g => {
                                if (!formikBag.values.country) {
                                    return;
                                }

                                if (
                                    formikBag.values.country === g.country &&
                                    (formikBag.values.country === usaCountryValue && formikBag.values.state
                                        ? !g.state || formikBag.values.state === g.state
                                        : true)
                                ) {
                                    options.push({ value: g.groupId, label: g.label });
                                }
                            });

                            if (options.length > 0) {
                                options.unshift({ value: '', label: `-- Choose ${organizationLabel} --` });
                            }

                            return (
                                <Field
                                    name="group"
                                    disabled={!options.length || !formikBag.values.country}
                                    component={CccisdSelect}
                                    label={organizationLabelLong + ' Name:'}
                                    options={options}
                                    showErrorImmediately
                                />
                            );
                        })()}

                        <div className={style.buttonContainer}>
                            <button className="btn btn-link" onClick={() => setPageNum(1)} type="button">
                                <IconLeft spaceRight />
                                Back
                            </button>
                            <button
                                className="btn btn-link"
                                onClick={() => {
                                    if (!submittedPageList.includes(2)) {
                                        setSubmittedPageList(prev => [...prev, 2]);
                                    }

                                    const errs = validatePageTwo(formikBag.values);
                                    if (Object.values(errs).some(err => err)) {
                                        formikBag.setErrors(errs);
                                    } else {
                                        setPageNum(3);
                                    }
                                }}
                                type="button"
                            >
                                Next
                                <IconRight spaceLeft />
                            </button>
                        </div>
                    </div>

                    <div style={{ display: pageNum === 3 && !formikBag.isSubmitting ? 'block' : 'none' }}>
                        <div className={style.summary}>
                            <p>Please review your information for accuracy before submitting:</p>
                            <div className="row">
                                <div className="col-12 col-md-5">
                                    <ul>
                                        <li>
                                            <b>First Name:</b> <i>{formikBag.values.first_name}</i>
                                        </li>
                                        <li>
                                            <b>Last Name:</b> <i>{formikBag.values.last_name}</i>
                                        </li>
                                        <li>
                                            <b>E-mail:</b> <i>{formikBag.values.email}</i>
                                        </li>
                                        <li>
                                            <b>Job Title:</b> <i>{formikBag.values.jobTitle}</i>
                                        </li>
                                    </ul>
                                </div>

                                {(() => {
                                    const selectedInstallation = installationList.find(
                                        g => Number(g.groupId) === Number(formikBag.values.group)
                                    );

                                    if (!selectedInstallation) {
                                        return <span />;
                                    }

                                    return (
                                        <div className="col-12 col-md-7">
                                            <ul>
                                                <li>
                                                    <b>{organizationLabel} Name:</b> <i>{selectedInstallation.label}</i>
                                                </li>
                                                <li>
                                                    <b>{organizationLabel} Location:</b>{' '}
                                                    <i>
                                                        {
                                                            countryField.values.find(
                                                                v => v.value === selectedInstallation.country
                                                            )?.name
                                                        }
                                                    </i>
                                                </li>
                                                {formikBag.values.country === usaCountryValue && (
                                                    <li>
                                                        <b>{organizationLabel} State:</b>{' '}
                                                        <i>
                                                            {
                                                                stateField.values.find(
                                                                    v => v.value === selectedInstallation.state
                                                                )?.name
                                                            }
                                                        </i>
                                                    </li>
                                                )}
                                                <li>
                                                    <b>{organizationLabel} Service Branch:</b>{' '}
                                                    <i>
                                                        {
                                                            serviceField.values.find(
                                                                v => v.value === selectedInstallation.service
                                                            )?.name
                                                        }
                                                    </i>
                                                </li>
                                            </ul>
                                        </div>
                                    );
                                })()}
                            </div>
                        </div>
                        <Field
                            name="termsandconds"
                            label="I accept the privacy policy and terms of use."
                            component={CccisdCheckboxInput}
                            labelNotBold
                        />
                        <div className={style.termsAndCondsLinks}>
                            <a href="https://www.3cisd.com/privacy-policy" target="_blank" rel="noopener noreferrer">
                                Privacy Policy
                            </a>
                            <a href="http://www.3cisd.com/terms-of-use" target="_blank" rel="noopener noreferrer">
                                Terms of Use
                            </a>
                        </div>

                        <div className={style.buttonContainer}>
                            <button className="btn btn-link" onClick={() => setPageNum(2)} type="button">
                                <IconLeft spaceRight />
                                Back
                            </button>
                            <button
                                className="btn btn-primary btn-lg"
                                onClick={() => {
                                    if (!submittedPageList.includes(3)) {
                                        setSubmittedPageList(prev => [...prev, 3]);
                                    }

                                    formikBag.handleSubmit();
                                }}
                                type="button"
                            >
                                Submit
                            </button>
                        </div>
                    </div>

                    <div style={{ display: formikBag.isSubmitting ? 'block' : 'none' }}>
                        <Loader type="inline" loading />
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default RegisterForm;
