import React, { useEffect, useState } from 'react';
import axios from 'cccisd-axios';
import { client } from 'cccisd-apollo';
import Loader from 'cccisd-loader';
import notification from 'cccisd-notification';
import { Redirect } from 'cccisd-react-router';
import checkUniqueQuery from './checkUniqueQuery.graphql';
import installationsQuery from './installations.graphql';
import RegisterForm from './form.js';

const Appdefs = window.cccisd.appDefs;
const Boilerplate = window.cccisd.boilerplate;
const Fortress = window.cccisd.fortress;

const OrgAdminRegister = () => {
    if (Fortress.auth()) {
        return <Redirect to="/" />;
    }

    const [pageNum, setPageNum] = useState(1);
    const [installationList, setinstallationList] = useState(null);
    useEffect(() => {
        async function fetchInstallations() {
            const resp = await client.query({ query: installationsQuery, fetchPolicy: 'network-only' });
            setinstallationList(
                resp.data.groups.organizationList.map(g => ({
                    ...g.group,
                    ...g.fields,
                }))
            );
        }

        if (!installationList) {
            fetchInstallations();
        }
    }, []);

    const organizationLabel = Appdefs.pawn.groups.find(g => g.handle === 'organization').label;

    const loginAfterRegister = async values => {
        const loginResponse = await axios.post(Boilerplate.route('api.nexus.login'), {
            username: values.username,
            password: values.password,
        });

        if (loginResponse.status !== 200) {
            notification({
                message: 'Registration was successful, but you could not login at this time.',
                type: 'danger',
                duration: 5000,
            });
            return;
        }

        if (loginResponse.data.notifications.length > 0) {
            loginResponse.data.notifications.forEach(note => notification(note));
        }

        if (loginResponse.data.status === 'success') {
            let afterLoginUrl = Fortress.settings.config.after_login_url;
            if (loginResponse.data.data.hasIncompleteSiteRegistration) {
                window.location = Boilerplate.url(`/account/registrationSurvey?redirectUrl=${afterLoginUrl}`);
            } else {
                window.location = Boilerplate.url(afterLoginUrl);
            }
        } else if (loginResponse.data.status === 'error') {
            notification({
                message: 'Registration was successful, but you could not login at this time.',
                type: 'danger',
                duration: 5000,
                data: loginResponse.data.errors,
            });
        }
    };

    const onRegisterSubmit = async values => {
        const selectedInstallation = installationList.find(g => Number(g.groupId) === Number(values.group));

        // before we create either Group or User, check for uniqueness
        const uniqueCheckResponse = await client.query({
            query: checkUniqueQuery,
            variables: {
                groupId: selectedInstallation.groupId,
                username: values.email,
            },
            fetchPolicy: 'network-only',
        });

        const existingGroup = uniqueCheckResponse.data?.groups?.organization;
        const isExistingUser = !!uniqueCheckResponse.data?.roles?.user?.username;
        if (isExistingUser) {
            setPageNum(1);
            // eslint-disable-next-line no-throw-literal
            throw { email: 'User with this email address already exists. Try logging in instead.' };
        }

        if (!existingGroup?.group?.groupId) {
            setPageNum(2);
            // eslint-disable-next-line no-throw-literal
            throw { group: `${organizationLabel} does not exist` };
        }

        if (existingGroup.childRoles.orgAdminCount > 0) {
            setPageNum(2);
            // eslint-disable-next-line no-throw-literal
            throw {
                group:
                    `Someone else already registered this ${organizationLabel}. ` +
                    `Try logging in instead, or contact your IPPW Team Lead for support.`,
            };
        }

        // Must copy values before manipulating or it will mutate Formik state object
        const pawnData = { ...values };
        pawnData.role = 'orgAdmin';
        pawnData.group = selectedInstallation.groupId;
        pawnData.username = pawnData.email;
        pawnData.selfRoster = true;
        const response = await axios.post(Boilerplate.route('api.nexus.pawn.store'), pawnData);

        if (response.status !== 200) {
            notification({
                message: 'Could not register at this time.',
                type: 'danger',
                duration: 0,
                data: response.data && response.data,
            });
        }

        if (response.data.status === 'success') {
            await loginAfterRegister(pawnData);
        } else if (response.data.status === 'error') {
            // If the email field is being used as username,
            // replace 'username' error with 'email' error
            if (!values.username && response.data.errors.username) {
                response.data.errors.email = response.data.errors.username;
                delete response.data.errors.username;
            }

            // errors may come back as something like {values: "Missing required middle name."},
            // so these will be displayed in a notification instead of styling in the form
            const isErrorLocationInForm = !Object.keys(response.data.errors).find(
                errLocation => !(errLocation in values)
            );

            // Throwing errors object will be picked up by formik, and style the incorrect input fields
            if (isErrorLocationInForm) {
                setPageNum(1);
                throw response.data.errors;
            } else {
                notification({
                    message: 'Failed to register',
                    duration: 10000,
                    type: 'danger',
                    data: response.data.errors,
                });
            }
        }

        if (response.data.notifications.length > 0) {
            response.data.notifications.forEach(note => notification(note));
        }
    };

    if (!installationList) {
        return <Loader loading />;
    }

    return (
        <RegisterForm
            installationList={installationList}
            onSubmit={onRegisterSubmit}
            pageNum={pageNum}
            setPageNum={setPageNum}
        />
    );
};

export default OrgAdminRegister;
